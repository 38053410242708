<template>
  <div class="recharge-record">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
    >
      <template slot="toolbar">
        <el-form-item label="充值时间">
          <el-date-picker
            size="small"
            style="width: 300px; margin-right: 18px"
            v-model="registerTime"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="registerTimeChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input
            size="small"
            placeholder="请输入企业名称"
            v-model="rechargeForm.shop_name"
            clearable
          ></el-input>
        </el-form-item>
        <div style="display: flex;margin-left: 18px">
          <el-button
            @click="getRechargeRecord"
            type="primary"
            size="small"
          >搜索
          </el-button>
          <div class="col"></div>
          <el-button
            @click="exportData"
            size="small"
            type="primary"
          >导出数据
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column
          prop="sn"
          label="财务流水号"
        />
        <el-table-column
          prop="shop_name"
          label="企业名称"
        />
        <el-table-column
          prop="create_time"
          label="充值时间"
        />
        <el-table-column
          prop="content"
          label="交易内容"
        />
        <el-table-column
          prop="remark"
          label="备注信息"
          show-overflow-tooltip
        />
        <el-table-column
          prop="creator"
          label="操作人"
          width="130"
        />
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
        background
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
  import * as API_account from "@/api/account";
  import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

  export default {
    name: "rechargeRecord",
    components: { EnTableLayout },
    data() {
      return {
        loading: false,
        // 商品数据
        tableData: {},
        registerTime: '',
        rechargeForm: {
          start: '',
          end: '',
          shop_name: '',
          page: 1,
          size: 20
        }
      }
    },
    methods: {
      registerTimeChange() {
        if (!this.registerTime) {
          this.rechargeForm.start = '';
          this.rechargeForm.end = '';
          return;
        }
        this.rechargeForm.start = new Date(this.registerTime[0]).getTime() / 1000;
        this.rechargeForm.end = (new Date(this.registerTime[1]).getTime() / 1000)+86399;
      },
      exportData() {
        this.loading = true;
        API_account.getRechargeRecord(this.rechargeForm)
          .then((response) => {
            this.loading = false;
            response.data.forEach(v => {
              const date = new Date(v.create_time * 1000)
              v.create_time = this.formatDate(date)
            })
            // 导出数据处理
            const json = {
              sheet_name: "平台充值记录",
              sheet_values: response.data.map((item) => ({
                财务流水号:item.sn,
                企业名称:item.shop_name,
                充值时间:item.create_time,
                交易内容:item.content,
                备注信息:item.remark,
                操作人:item.creator,
              })),
            };
            this.MixinExportJosnToExcel(json, "平台充值记录");
          })
          .catch(() => (this.loading = false));
      },
      /** 获取充值记录 */
      getRechargeRecord() {
        this.loading = true;
        API_account.getRechargeRecord(this.rechargeForm)
          .then((response) => {
            this.loading = false;
            if(this.tableData.data_total!==response.data_total) {
              this.rechargeForm.page = 1;
              this.tableData = response;
              this.getRechargeRecord()
            }
            else {
              this.tableData = response;
              this.tableData.data.forEach(v => {
                const date = new Date(v.create_time * 1000)
                v.create_time = this.formatDate(date)
              })
            }
          })
          .catch(() => (this.loading = false));
      },
      /** 分页大小发生改变 */
      handlePageSizeChange(size) {
        this.rechargeForm.size = size;
        this.getRechargeRecord();
      },
      /** 分页页数发生改变 */
      handlePageCurrentChange(page) {
        this.rechargeForm.page = page;
        this.getRechargeRecord();
      },
      formatDate(date) {
        const y = date.getFullYear()
        const month = date.getMonth()+1 >= 10 ? date.getMonth()+1 : '0' + (date.getMonth()+1)
        const d = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
        const h = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
        const m = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
        const s = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
        return y+'-'+month+'-'+d+' '+h+':'+m+':'+s
      }
    },
    mounted() {
      this.getRechargeRecord()
    }
  }
</script>

<style lang="scss" scoped>
  .recharge-record {
  }
</style>
